.info__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: var(--margin) 0;
  width: calc(100% - 2 * var(--margin));
  margin: var(--margin) auto;
  .btn {
    margin-top: 24px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.header__info {
  font-size: 48px;
  font-weight: bold;
  text-transform: uppercase;
  span {
    margin: 0 20px;
    color: var(--color-accent-contrast);
    a {
      text-decoration: none;
      color: inherit;
    }
  }
}