form {
  display: flex;
  flex-direction: column;
  row-gap: var(--margin);
  margin: var(--margin) auto;
  padding: var(--margin) 0;
  width: calc(100% - 2 * var(--margin));
  position: relative;
  max-width: 720px;
}

.error {
  color: var(--color-error);
  display: block;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 400;
  text-transform: none;
}

.form__control {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;

  label, p {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 32px;
    font-weight: 600;
    text-transform: uppercase;
    row-gap: 12px;
    font-family: inherit;
    @media screen and (max-width: 640px){
      font-size: 24px;
    }

    &.form__question {
      font-size: 40px;
      text-transform: none;
      margin-bottom: 0.5em;
    }

    &.label__row {
      flex-direction: row;
      align-items: center;
      height: var(--input-height);
      column-gap: 10px;
      margin-bottom: 10px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  select, input[type="text"], textarea {
    border: 2px solid var(--color-text);
    width: 100%;

    &:focus {
      outline-offset: 3px;
      outline: var(--color-accent-contrast) dotted 3px;
    }

  }

  input, select, textarea {
    appearance: none;
    border-radius: 0;
    height: var(--input-height);
    font-size: 20px;
    padding: 0 10px;
    font-family: inherit;
    position: relative;
    background-color: var(--bg-color);
    color: var(--color-text);
  }

  textarea {
    resize: none;
    height: auto;
    min-height: calc(var(--input-height) * 2);
    padding: 10px;
  }

  input[type="radio"], input[type="checkbox"] {
    width: calc(0.75 * var(--input-height));
    height: calc(0.75 * var(--input-height));
    border: 4px solid var(--color-text);
    background-color: transparent;
    position: relative;
    &:hover {
      cursor: pointer;
    }
  }

  input[type="radio"] {
    border-radius: 50%;
    &:checked {
      background-color: var(--color-text);
    }
    &:hover {
      background-color: var(--color-text);
    }
  }

  input[type="checkbox"] {
    &:before {
      display: block;
      position: absolute;
      content: 'X';
      text-transform: uppercase;
      font-weight: bold;
      font-size: calc(0.75 * var(--input-height));
      line-height: 1;
      padding: 0;
      left: 50%;
      top: 50%;
      transform: translate(-52%, -50%) scaleX(1.3);
      opacity: 0;
    }
    &:checked {
      &:before {
        opacity: 1;
      }
    }
    &:hover {

    }
  }

  select {
    background-color: transparent;

    &:hover {
      cursor: pointer;
    }
  }

  .arrow__select {
    display: block;
    position: absolute;
    bottom: calc(0.4 * var(--input-height));
    right: 20px;
    width: 20px;
    height: 20px;
    border-bottom: 3px solid var(--color-text);
    border-right: 3px solid var(--color-text);
    transform: rotate(45deg);
  }
}
