.btn {
    background-color: var(--color-text);
    color: var(--bg-color);
    appearance: none;
    border: 4px solid var(--color-text);
    min-height: var(--input-height);
    font-family: inherit;
    font-size: 36px;
    font-weight: 400;
    &:hover {
        background-color: var(--bg-color);
        color: var(--color-text);
        cursor: pointer;
    }
}