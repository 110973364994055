li {
  height: var(--input-height);
  position: relative;
  width: 100%;
  @media screen and (max-width: 880px){
    max-width: calc(100% - var(--input-height));
  }
  input {
    border: none;
    width: 100%;
    border-bottom: 4px solid var(--color-text);
  }
  button {
    min-width: var(--input-height);
    min-height: var(--input-height);
    position: absolute;
    background-color: transparent;
    color: var(--color-text);
    appearance: none;
    font-family: inherit;
    font-size: var(--padding);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    bottom: 0;
    transform: translateX(100%);
    &:hover:not([disabled]) {
      background-color: var(--color-text);
      color: var(--bg-color);
      cursor: pointer;
    }
  }
}