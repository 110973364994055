.arrow {
  width: var(--arrow-size);
  height: calc(0.7 * var(--arrow-size));
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: var(--margin);
  position: absolute;
  bottom: 50px;
  right: 50px;
  span {
    position: absolute;
    top: 0;
    left: 50%;
    width: var(--arrow-size);
    height: var(--arrow-size);
    border-left: calc(0.1 * var(--arrow-size)) solid var(--color-text);
    border-bottom: calc(0.1 * var(--arrow-size)) solid var(--color-text);
    transform:  translate(-50%, -150%) rotate(-45deg);
    animation: scrollDown 2.5s infinite cubic-bezier(0.3, 0.5, 0.5, 0.7);
    &:nth-of-type(2) {
      animation-delay: 0.5s;
    }
    &:nth-of-type(3) {
      animation-delay: 1s;
    }
    &:nth-of-type(4) {
      animation-delay: 1.5s;
    }
    &:nth-of-type(5) {
      animation-delay: 2s;
    }
  }


}

@keyframes scrollDown {
  0% {
    transform:  translate(-50%, -150%) rotate(-45deg);
  }
  50% {
  }
  100% {
    transform:  translate(-50%, 200%) rotate(-45deg);
  }
}