.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    input {
        opacity: 0;
        width: 0;
        height: 0;
        &:checked + span {
            background-color: var(--color-text);
            &:before {
                transform: translateX(26px);
            }
        }
        &:focus + span {
            box-shadow: 0 0 1px var(--color-text);
        }
    }
    span {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--color-text);
        transition: .2s;
        border-radius: 34px;
        &:before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: var(--bg-color);
            transition: .2s;
            border-radius: 50%;
        }
    }
}