header {
  height: var(--header-height);
  width: calc(100% - 2 * var(--margin));
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 20px;
  padding: 0;
  border-bottom: 4px solid var(--color-text);
  position: relative;
  z-index: 3;

  .switch {
    margin-right: auto;
    margin-left: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 30px;
    transition: background-size 0.4s ease;
    background-image: linear-gradient(
                    transparent calc(50% - 2px),
                    var(--color-text) calc(50% - 2px),
                    var(--color-text) calc(50% + 2px), transparent calc(50% + 2px)
    );
    background-repeat: no-repeat;
    background-size: 0 100%;

    &:hover, &.active {
      background-size: 100% 100%;
    }
  }
}