.graph__container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 2 * var(--margin));
  margin: var(--margin) auto;
}

.graph__pie {
  position: relative;
  height: 50vmin;
  width: 50vmin;
  margin: 0 auto;
  @media screen and (orientation: portrait) {
    height: 75vw;
    width: 75vw;
  }
}

.graph__bar {
  position: relative;
  width: 90vw;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  @media screen and (orientation: landscape) {
    max-height: 40vh;
  }
}