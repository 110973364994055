.grid__item {
  border: 2px solid var(--color-text);
  //aspect-ratio: 3/2;
  font-weight: bold;
  --b: 2px; /* border length*/
  --d: 20px; /* the cube depth */
  --_s: calc(var(--d) + var(--b));
  border-width: var(--b) var(--b) var(--_s) var(--_s);
  background: conic-gradient(at left var(--d) bottom var(--d),
          var(--color-gradient) 0 90deg, rgb(255 255 255 /0.3) 0 225deg, rgb(255 255 255 /0.6) 0) border-box,
  conic-gradient(at left var(--_s) bottom var(--_s),
                  var(--color-gradient) 0 90deg, var(--color-main) 0) 0 100%/calc(100% - var(--b)) calc(100% - var(--b)) border-box;
  transform: translate(calc(var(--d) / -1), var(--d));
  clip-path: polygon(var(--d) 0%, var(--d) 0%, 100% 0%, 100% calc(100% - var(--d)), 100% calc(100% - var(--d)), var(--d) calc(100% - var(--d))
  );
  transition: 0.5s;

  &:hover {
    cursor: pointer;
    transform: translate(0, 0);
    clip-path: polygon(
                    0% var(--d),
                    var(--d) 0%,
                    100% 0%,
                    100% calc(100% - var(--d)),
                    calc(100% - var(--d)) 100%,
                    0% 100%
    );
  }

  * {
    text-decoration: none;
    color: inherit;
  }

  a {
    padding: var(--padding);
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;

    &:before {
      display: block;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;
    }

    h2 {
      font-size: var(--grid-font-size);
    }
  }

  &.grid__item--new {
    a {
      font-size: calc(3 * var(--grid-font-size));
      font-weight: 300;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;

      &:before {
        background-color: var(--color-accent);
      }
    }
  }

  &.grid__item--open {
    a {
      &:before {
        background-color: var(--color-open);
      }
    }
  }

  &.grid__item--radio {
    a {
      &:before {
        background-color: var(--color-radio);
      }
    }
  }

  &.grid__item--checkbox {
    a {
      &:before {
        background-color: var(--color-checkbox);
      }
    }
  }
}

