main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: calc(100vh - var(--header-height));
  padding: var(--padding) var(--margin);
  position: relative;
  @media screen and (orientation: portrait) {
    justify-content: flex-start;
    padding-top: 7.5vh;
  }
  h2 {
    text-transform: uppercase;
    -webkit-text-stroke: 2px var(--color-text);
    line-height: 0.75;
    position: relative;
    @media screen and (orientation: portrait) {
      width: 100%;
    }
    &:hover {
      cursor: pointer;
    }
    span {
      position: absolute;
      font-size: 32px;
      line-height: 1.3;
      -webkit-text-stroke: initial;
      font-weight: 500;
      width: 100%;
      background-color: var(--bg-color);
      border: 4px solid var(--color-text);
      padding: 20px;
      left: 50%;
      color: var(--color-text);
      display: none;
      opacity: 0.9;
      @media screen and (max-width: 769px) {
        padding: 10px;
        font-size: 24px;
      }
      @media screen and (max-width: 769px) and (orientation: landscape) {
        width: 80vw;
        left: 0;
      }
    }

    &:nth-of-type(1) {
      font-size: clamp(36px, 40vh, 30vw);
      z-index: 1;
      &:hover {
        color: var(--color-main);
        z-index: 2;
        span {
          display: block;
        }
      }
      span {
        bottom: 0;
        transform: translate(-50%, 120%);
        @media screen and (max-width: 769px) and (orientation: landscape) {
          transform: translateY(110%);
        }
      }
    }

    &:nth-of-type(2) {
      font-size: clamp(36px, 18vh, 16vw);
      margin-left: auto;
      color: var(--color-main);
      @media screen and (orientation: portrait) {
        padding: 7.5vh 0 10vh;
        line-height: 1;
      }
    }

    &:nth-of-type(3) {
      font-size: clamp(36px, 30vh, 19.5vw);
      z-index: 1;
      @media screen and (orientation: portrait) {
        font-size: clamp(36px, 30vh, 21.5vw);
      }
      &:hover {
        color: var(--color-main);
        span {
          display: block;
        }
      }
      span {
        top: 0;
        transform: translate(-50%, -120%);
        @media screen and (max-width: 769px) and (orientation: landscape) {
          transform: translateY(-110%);
        }
      }
    }
  }
  .arrow {
    @media screen and (orientation: portrait) {
      position: relative;
      margin-left: auto;
      right: auto;
      bottom: auto;
      margin-top: 5vh;
    }
  }
}