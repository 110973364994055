.results__container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

//swiper

.swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    max-width: 75vw;
    height: 300px;
    border: 2px solid var(--color-text);
    font-size: 32px;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 20px;
    overflow-y: auto;
    &:nth-of-type(4n + 1) {
        background-color: var(--color-accent);
    }
    &:nth-of-type(4n + 2) {
        background-color: var(--color-open);
    }
    &:nth-of-type(4n + 3) {
        background-color: var(--color-radio);
    }
    &:nth-of-type(4n + 4) {
        background-color: var(--color-checkbox);
    }
}

.swiper-pagination {
    width: 300px;
    height: 10px;
}